<template >
    <div class="modal-result">
        <!-- Modal -->
     <vue-final-modal
      :click-to-close="false"
        v-model="fncShowModal"
          classes="modal-container"
          content-class="modal-content"
        >
        <span class="modal__title title-modal" :style="`color: ${genColor(formData.RiskLevel)}`">{{renderTitle(formData.RiskLevel)}}</span>
        <div class="modal__content">
          <div v-if="formData.RiskLevel === riskLevel.zero">
            <div class="show-img" v-if="mode  === 'EN'">
              <img src="@/assets/green-icon-en(1).png" class="img-result">
              <img src="@/assets/green-icon-en(2).png" class="img-result">
              <img src="@/assets/green-icon-en(3).png" class="img-result">
            </div>
            <div class="show-img" v-else>
              <img src="@/assets/green-icon(1).png" class="img-result">
              <img src="@/assets/green-icon(2).png" class="img-result">
              <img src="@/assets/green-icon(3).png" class="img-result">
            </div>
            <p style="color:green; text-align:center">{{mode === 'EN' ? 'Work as usual' : 'เข้าทำงานตามปกติ'}}</p>
          </div>
          <div v-else-if="formData.RiskLevel === riskLevel.normal">
            <div class="show-img" v-if="mode === 'EN'">
              <img src="@/assets/blue-icon-en(1).png" class="img-result">
              <img src="@/assets/blue-icon-en(2).png" class="img-result">
              <img src="@/assets/blue-icon-en(3).png" class="img-result">
            </div>
            <div class="show-img" v-else>
              <img src="@/assets/blue-icon(1).png" class="img-result">
              <img src="@/assets/blue-icon(2).png" class="img-result">
              <img src="@/assets/blue-icon(3).png" class="img-result">
            </div>
            <div class="action-text">
                <li>{{mode === 'EN' ? 'Work as usual' : 'เข้าทำงานตามปกติ'}} </li>
                <li>{{mode === 'EN' ? 'Always Self Monitoring' : 'Self Monitoring อย่างสม่ำเสมอ'}}</li>
            </div>
          </div>
          <div v-else-if="formData.RiskLevel === riskLevel.high">
            <div class="show-img" v-if="mode === 'EN'">
              <img src="@/assets/yellow-icon-en(1).png" class="img-result" >
              <img src="@/assets/yellow-icon-en(2).png" class="img-result" >
              <img src="@/assets/yellow-icon-en(3).png" class="img-result" >
            </div>
             <div class="show-img" v-else>
              <img src="@/assets/yellow-icon(1).png" class="img-result" >
              <img src="@/assets/yellow-icon(2).png" class="img-result" >
              <img src="@/assets/yellow-icon(3).png" class="img-result" >
            </div>
            <div class="action-text">
                <li>Work From Home</li>
                <li>Self Monitoring 14 วัน</li>
            </div>
            
          </div>
          <div v-else>
            <div class="show-img" v-if="mode === 'EN'">
              <img src="@/assets/red-icon-en(1).png" class="img-result" >
              <img src="@/assets/red-icon-en(2).png" class="img-result">
              <img src="@/assets/red-icon-en(3).png" class="img-result">
            </div>
            <div class="show-img" v-else>
              <img src="@/assets/red-icon(1).png" class="img-result" >
              <img src="@/assets/red-icon(2).png" class="img-result">
              <img src="@/assets/red-icon(3).png" class="img-result">
            </div>
            <div class="action-text">
                <li>{{mode === 'EN' ? 'COVID Testing (F1)' : 'ตรวจเชื้อ (F1)'}}</li>
                <li>{{mode === 'EN' ? 'Self Quarantine for 14 days' : 'Self Quarantine 14 วัน'}}</li>
            </div>
          </div>
           <div>
              <h6 class="modal_description"> {{mode ==='EN' ? 'Complete the form on ' : 'ทำแบบประเมินวันที่' }} {{showDate()}}</h6>
              <h6 class="modal_description">*{{mode === 'EN' ? 'Please capture the screen after completing the form' : 'กรุณาบันทึกหน้าจอไว้เพื่อแสดงเป็นหลักฐานยืนยัน'}}*</h6>
            </div>
          <div class="operation-modal">
              <button class="btn btn-info btn-sm"  @click="closeModal" :style="`border:0;width:100px; background-color: ${genColor(formData.RiskLevel)}`">I Agree</button>
          </div>
        </div>
      </vue-final-modal>
    </div>
</template>
<script>
import Moment from 'moment'
export default {
    props: {
        showModal: {
          type: Boolean
        },
        riskLevel: {
          type: Object,
          requried: true
        },
        formData: {
          type: Object,
          requried: true
        }
    },
    data () {
      return {
        mode: localStorage.getItem('lang') || 'TH'
      }
    },
    computed: {
      fncShowModal () {
        return this.showModal
      }
    },
    methods: {
        renderTitle(data) {
          if (this.mode === 'EN') {
            if (data === this.riskLevel.zero) return 'You have a low risk'
            else if (data === this.riskLevel.normal) return 'You have intermediate risk'
            else if (data === this.riskLevel.high) return 'You have a high risk'
            else return 'You have a very high risk'
          }
        },
        showDate() {
          return Moment().format('DD MMM YYYY')
        },
        genColor(data) {
            switch (data) {
                case this.riskLevel.zero:
                return '#00DC72'
                case this.riskLevel.normal:
                return '#2BA4FC'
                case this.riskLevel.high:
                return '#FCC12B'
                default:
                return '#FA1825'
            }
        },
        closeModal () {
          location.reload()
        },
    }
}
</script>

<style lang="scss" scoped>
.modal-result {
    .img-result {
    width: 90px;
    height: 90px;
    margin-right: 10px;
  }
  .show-img {
    display: flex;
    justify-content: inline;
    padding-bottom: 20px;
    margin-left: 40px;
  }
  .title-modal {
    text-align: center;
    padding-bottom: 20px
  }
  .action-text {
      padding-left: 30px;
    }
    .operation-modal {
        padding-top:10px;
        text-align: center;
    }
    ::v-deep(.modal-container) {
    display: flex;
    justify-content: center;
    align-items: center;
    }
    ::v-deep(.modal-content) {
    display: flex;
    flex-direction: column;
    margin: 0 1rem;
    padding: 1rem;
    max-width:  400px !important;
    border: 1px solid #e2e8f0;
    border-radius: 0.25rem;
    background: #fff;
    }
    .modal__title {
    font-size: 1.5rem;
    font-weight: 700;
    }
    .dark-mode div ::v-deep(.modal-content) {
    border-color: #2d3748;
    background-color: #1a202c;
    }
    .modal_description {
      font-size: 14px;
    margin-top: 6px;
    color: red;
     text-align: center;
    }
}
</style>
